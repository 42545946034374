import { map } from 'lodash';
import { Actions, ActionTypes } from '../actions/hardware-interface-configuration.action';
import { HardwareInterfaceConfigurationReducer } from '../models/hardware-interface-configuration.model';

export const initialState: HardwareInterfaceConfigurationReducer = {
  id: 0,
  deviceId: 0,
  interfaces: [],
  shuffledColumns: [
    { key: 'Name', field: 'user_defined_name', hide: false },
    { key: 'MAC Address', field: 'mac_address', hide: false },
    { key: 'Admin Status', field: 'enable_interface', hide: false },
    { key: 'Dynamic IP', field: 'dynamic_ip_addressing_mode', hide: false },
    { key: 'DHCP Server', field: 'dhcp_server_configurations', hide: false },
    { key: 'Runtime Status', field: 'runtime_status', hide: false },
    { key: 'IP Addresses', field: 'system_defined_ip_addresses', hide: false },
    { key: 'Default Gateway', field: 'default_gateway', hide: false },
    { key: 'Bridge', field: 'bridge_id', hide: false },
    { key: 'Type', field: 'network_side', hide: false },
    { key: 'TX-Packets', field: 'statistics.sent.packets', hide: false },
    { key: 'TX-Bytes', field: 'statistics.sent.bytes', hide: false },
    { key: 'TX-Errors', field: 'statistics.sent.errors', hide: false },
    { key: 'RX-Packets', field: 'statistics.received.packets', hide: false },
    { key: 'RX-Bytes', field: 'statistics.received.bytes', hide: false },
    { key: 'RX-Errors', field: 'statistics.received.errors', hide: false },
  ],
};

export function hardwareInterfaceConfigurationReducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ActionTypes.SET_HARDWARE_INTERFACE_CONFIGURAION:
      return {
        ...state,
        id: action.payload.id,
        deviceId: action.payload.device_id,
        interfaces: action.payload.interfaces,
      };
    case ActionTypes.CLEAR_CURRENT_HARDWARE_INTERFACE_CONFIGURATION:
      return initialState;
    case ActionTypes.EDIT_HARDWARE_INTERFACE:
      const updatedInterfaceList = map(state.interfaces, (hardwareInterface) =>
        hardwareInterface.id === action.payload.id
          ? { ...hardwareInterface, ...action.payload }
          : hardwareInterface
      );
      return {
        ...state,
        interfaces: updatedInterfaceList,
      };
    case ActionTypes.HARDWARE_INTERFACE_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case ActionTypes.HARDWARE_INTERFACE_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case ActionTypes.HARDWARE_INTERFACE_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case ActionTypes.HARDWARE_INTERFACE_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    default:
      return state;
  }
}
