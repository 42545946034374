import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { DNAT } from '@secure/devices/store/models/dnat.model';
import {
  StaticRouting,
  StaticRoutingSaveToCloudReq,
} from '@secure/devices/store/models/static-routing.model';
import { VpnRouting, VpnRoutingServer } from '@secure/devices/store/models/vpn-routing.model';
import { HW_ID_REGEX } from '@shared/constants/constants';
import { Message } from '@shared/models/shared.model';
import { StoreState } from 'app/store/store';
import { environment } from 'environments/environment';
import { find, forEach, map } from 'lodash';
import { Observable, interval } from 'rxjs';
import { map as rxjsMap, startWith, switchMap, take, takeWhile } from 'rxjs/operators';
import {
  clearIpsecCertificateAction,
  setIpsecCertificateAction,
} from '../configuration/ipsec/certificate/store/ipsec-certificate.action';
import {
  clearIpsecClientAction,
  setIpsecClientAction,
} from '../configuration/ipsec/client/store/ipsec-client.action';
import { IpsecServerIdNames } from '../configuration/ipsec/client/store/ipsec-client.interface';
import { setIpsecStatisticsAction } from '../configuration/ipsec/ipsec-statistics/store/ipsec-statistics.action';
import { IpsecClients } from '../configuration/ipsec/ipsec.interface';
import {
  clearIpsecServerAction,
  setIpsecServerAction,
} from '../configuration/ipsec/server/store/ipsec-server.action';
import {
  SetDeviceVersion,
  SetLocallyChangedField,
  SetVersionChanged,
} from '../store/actions/device-configuration.action';
import {
  AddDnat,
  DeleteDnat,
  EditDnat,
  SetDnatConfiguration,
} from '../store/actions/dnat-configuration.action';
import { SetIpsecInterface } from '../store/actions/ipsec-interface.action';
import { SetPrismaInterface } from '../store/actions/prisma-interface.action';
import {
  AddQoSClass,
  DeleteQoSClass,
  EditQoSClass,
  SetQoSById,
  UpdateQosColumnWidth,
} from '../store/actions/qos-configuration.action';
import {
  AddRemoteSSH,
  DeleteRemoteSSH,
  EditRemoteSSH,
  SetRemoteSSHConfiguration,
  UpdateRemoteSSHColumnWidth,
} from '../store/actions/remote-ssh-configuration.action';
import { AddSplitDns, EditSplitDns } from '../store/actions/split-dns-configuration.action';
import {
  AddStaticRouting,
  DeleteStaticRouting,
  EditStaticRouting,
  SetStaticRoutingConfiguration,
  StaticRoutingRedistributeChange,
  UpdateStaticRoutingColumnWidth,
} from '../store/actions/static-routing-configuration.action';
import {
  SetStaticRoutingDetailConfiguration,
  UpdateStaticRoutingDetailColumnWidth,
} from '../store/actions/static-routing-detail-configuration.action';
import { SetTrafficSteeringById } from '../store/actions/traffic-steering-configuration.action';
import { SetTrafficSteeringStatistics } from '../store/actions/traffic-steering-statistics.action';
import { SetTunnelInterfaceConfiguration } from '../store/actions/tunnel-interface-configuration.action';
import {
  AddVpnRouting,
  DeleteVpnAndRouting,
  EditVpnRouting,
  SetVpnRoutingConfiguration,
  UpdateVpnRoutingColumnWidth,
} from '../store/actions/vpn-routing-configuration.action';
import {
  AddVpnRoutingServer,
  DeleteVpnAndRoutingServer,
  EditVpnRoutingServer,
  SetVpnRoutingServerConfiguration,
  UpdateVpnRoutingServerColumnWidth,
} from '../store/actions/vpn-routing-server-configuration.action';
import { BridgeInterface } from '../store/models/bridge-interface-configuration.model';
import { IpsecServersResponse } from '../store/models/device-configuration.model';
import { DeviceBasicDetails, DeviceData, MultiSelect } from '../store/models/devices.model';
import { HardwareInterface } from '../store/models/hardware-interface-configuration.model';
import * as type from '../store/models/qos-configuration.model';
import { QoSClass } from '../store/models/qos-configuration.model';
import { RemoteSSH } from '../store/models/remote-ssh.model';
import { TrafficSteering } from '../store/models/traffic-steering-configuration.model';
import {
  PACKET_CAPTURE_STATUS,
  PacketCaptureRequestPrams,
  PacketCaptureStatusResponse,
} from '../store/models/troubleshooting.model';
import { getCurrentDeviceQoSClasses } from '../store/selectors';
import { getCurrentDeviceBridgeInterfaces } from '../store/selectors/bridge-interface-configuration.selector';
import {
  getCurrentDeviceConfigVersion,
  getLocallyChangedFlag,
} from '../store/selectors/device-configuration.selector';
import { getCurrentDeviceHardwareInterfaces } from '../store/selectors/hardware-interface-configuration.selector';
import {
  getBridgeInterfaces,
  getHardwareInterfaces,
  getLANInterfaces,
  getMappedBridge,
  getMappedVlan,
  getNetworkInterfaces,
  getWANInterfaces,
  networkInterfaceMembers,
} from '../store/selectors/network-interface.selector';
import { getCurrentDeviceSplitDnses } from '../store/selectors/split-dns-configuration.selector';
import { getCurrentDeviceStaticRoutingsSaveToCloud } from '../store/selectors/static-router-configuration.selector';
import { getCurrentTrafficSteering } from '../store/selectors/traffic-steering-configuration.selector';
import {
  getCurrentDeviceTunnelInterfaces,
  getTUNInterfaces,
} from '../store/selectors/tunnel-interface-configuration.selector';
import { getCurrentDeviceVlanInterfaces } from '../store/selectors/vlan-interface-configuration.selector';
import {
  AddBridgeInterface,
  AddBridgeMember,
  DeleteBridgeMember,
  EditBridgeInterface,
  SetBridgeInterfaceConfiguration,
  UpdateBridgeMembers,
} from './../store/actions/bridge-interface-configuration.action';
import {
  EditHardwareInterface,
  SetHardwareInterfaceConfiguration,
} from './../store/actions/hardware-interface-configuration.action';
import {
  DeleteSplitDns,
  SetSplitDnsConfiguration,
  UpdateSplitDnsColumnWidth,
} from './../store/actions/split-dns-configuration.action';
import { SetUsbInterfaceConfiguration } from './../store/actions/usb-interface-configuration.action';
import {
  AddVlanInterface,
  DeleteVlanInterface,
  EditVlanInterface,
  SetVlanInterfaceConfiguration,
} from './../store/actions/vlan-interface-configuration.action';
import { SetWirelessInterfaceConfiguration } from './../store/actions/wireless-interface-configuration.action';
import { MappedInterfaces, NetworkInterface } from './../store/models/shared.model';
import { SplitDns } from './../store/models/split-dns.model';
import { VlanInterface } from './../store/models/vlan-interface-configuration.model';
import { getCurrentDeviceDnats } from './../store/selectors/dnat-configuration.selector';
import { getCurrentDeviceRemoteSSHs } from './../store/selectors/remote-ssh-configuration.selector';
import { getCurrentDeviceVpnRoutings } from './../store/selectors/vpn-routing-configuration.selector';
import { getCurrentDeviceVpnRoutingServers } from './../store/selectors/vpn-routing-server-configuration.selector';
import {
  SetDeviceBasicDetails,
  SetOrganizationForTheDevice,
} from '../store/actions/devices.action';

@Injectable({
  providedIn: 'root',
})
export class DeviceConfigurationService {
  version: number;
  currentDeviceData: DeviceData;
  private apiUrl = environment.baseUrl;

  constructor(
    public store: Store<StoreState>,
    private httpClient: HttpClient
  ) {
    this.store
      .pipe(select(getCurrentDeviceConfigVersion))
      .subscribe((version) => (this.version = version));
  }

  saveToStore(data: DeviceData) {
    this.store.pipe(select(getLocallyChangedFlag), take(1)).subscribe((isLocallyChanged) => {
      if (isLocallyChanged) {
        if (data.device_configuration && data.device_configuration.version !== this.version) {
          this.store.dispatch(new SetVersionChanged(true));
        }
      } else if (!isLocallyChanged) {
        this.currentDeviceData = data;
        this.clearStore();

        this.store.dispatch(
          new SetDeviceVersion(data.device_configuration ? data.device_configuration.version : 0)
        );

        const deviceBasicDetails: DeviceBasicDetails = {
          name: data.name,
          uuid: data.uuid,
          location: data.location,
          latitude: data.latitude,
          longitude: data.longitude,
          organizationName: data.organization.name,
          organizationId: data.organization_id,
        };
        this.store.dispatch(new SetDeviceBasicDetails(deviceBasicDetails));

        if (data.traffic_steering_detail) {
          this.store.dispatch(new SetTrafficSteeringStatistics(data.traffic_steering_detail));
        }
        if (data.traffic_steering_configuration) {
          data.traffic_steering_configuration.traffic_steering.forEach((trafficSteering) => {
            if (
              trafficSteering.destination_domains &&
              trafficSteering.destination_domains[0]['id']
            ) {
              const id = [];
              const name = [];
              trafficSteering.destination_domains.forEach((domain) => {
                id.push(parseInt(domain['id']));
                name.push(domain['name']);
              });
              trafficSteering.destination_domains = [...id];
              trafficSteering.destination_domain_names = [...name];
            }
          });
          this.store.dispatch(new SetTrafficSteeringById(data.traffic_steering_configuration));
        }
        if (data.qos_configuration) {
          this.store.dispatch(new SetQoSById(data.qos_configuration));
        }
        if (data.hardware_interface_configuration) {
          this.store.dispatch(
            new SetHardwareInterfaceConfiguration(data.hardware_interface_configuration)
          );
        }
        if (data.wireless_interface_configuration) {
          this.store.dispatch(
            new SetWirelessInterfaceConfiguration(data.wireless_interface_configuration)
          );
        }
        if (data.usb_interface_configuration) {
          this.store.dispatch(new SetUsbInterfaceConfiguration(data.usb_interface_configuration));
        }
        if (data.tunnel_interface_configuration) {
          this.store.dispatch(
            new SetTunnelInterfaceConfiguration(data.tunnel_interface_configuration)
          );
        }
        if (data.ipsec_interfaces) {
          this.store.dispatch(new SetIpsecInterface(data.ipsec_interfaces));
        }
        if (data.prisma_interfaces) {
          this.store.dispatch(new SetPrismaInterface(data.prisma_interfaces));
        }
        if (data.vlan_interface_configuration) {
          this.store.dispatch(new SetVlanInterfaceConfiguration(data.vlan_interface_configuration));
        }
        if (data.bridge_interface_configuration) {
          this.store.dispatch(
            new SetBridgeInterfaceConfiguration(data.bridge_interface_configuration)
          );
        }
        if (data.split_dns_configuration) {
          this.store.dispatch(new SetSplitDnsConfiguration(data.split_dns_configuration));
        }
        if (data.vpn_and_routing_configuration?.vpn_and_routing) {
          this.store.dispatch(new SetVpnRoutingConfiguration(data.vpn_and_routing_configuration));
        }

        if (data.vpn_and_routing_configuration?.vpn_and_routing_server) {
          this.store.dispatch(
            new SetVpnRoutingServerConfiguration(data.vpn_and_routing_configuration)
          );
        }
        if (data.static_routing_configuration) {
          this.store.dispatch(new SetStaticRoutingConfiguration(data.static_routing_configuration));
        }
        if (data.static_routing_detail) {
          this.store.dispatch(new SetStaticRoutingDetailConfiguration(data.static_routing_detail));
        }
        if (data.dnat_configuration) {
          this.store.dispatch(new SetDnatConfiguration(data.dnat_configuration));
        }
        if (data.ssh_key || data.remote_ssh_configuration) {
          this.store.dispatch(
            new SetRemoteSSHConfiguration({
              ssh_key: data.ssh_key,
              remote_ssh: data.remote_ssh_configuration?.remote_ssh || [],
            })
          );
        }
        if (data.ipsec_configurations) {
          this.store.dispatch(
            setIpsecServerAction({
              servers: data.ipsec_configurations?.ipsec_servers,
            })
          );
          this.store.dispatch(
            setIpsecClientAction({
              clients: data.ipsec_configurations?.ipsec_clients,
            })
          );
          this.store.dispatch(
            setIpsecCertificateAction({
              certificates: data.ipsec_configurations?.ipsec_certificates,
            })
          );
        }
        if (data.ipsec_details) {
          const statistics = map(data.ipsec_details.ipsec_details, (connection) => connection);
          this.store.dispatch(
            setIpsecStatisticsAction({
              ipsec_statistics: statistics,
            })
          );
        }
        if (data.organization) {
          this.store.dispatch(new SetOrganizationForTheDevice(data.organization));
        }
      }
    });
  }

  clearStore(): void {
    this.store.dispatch(clearIpsecServerAction());
    this.store.dispatch(clearIpsecClientAction());
    this.store.dispatch(clearIpsecCertificateAction());
  }

  getCurrentDeviceVersion(): number {
    let deviceVersion: number;
    this.store
      .pipe(select(getCurrentDeviceConfigVersion))
      .subscribe((version) => (deviceVersion = version));
    return deviceVersion;
  }

  addToQoSStore(qosClass: type.QoSClass) {
    this.store.dispatch(new AddQoSClass(qosClass));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  addToSplitDNSStore(splitDnsConfiguration: SplitDns) {
    this.store.dispatch(new AddSplitDns(splitDnsConfiguration));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  updateSplitDNSStore(splitDnsConfiguration: SplitDns) {
    this.store.dispatch(new EditSplitDns(splitDnsConfiguration));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  addToVPNRoutingStore(vpnRoutingConfiguration: VpnRouting) {
    this.store.dispatch(new AddVpnRouting(vpnRoutingConfiguration));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  updateVPNRoutingStore(vpnRoutingConfiguration: VpnRouting) {
    this.store.dispatch(new EditVpnRouting(vpnRoutingConfiguration));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  addToVPNRoutingServerStore(vpnRoutingServerConfiguration: VpnRoutingServer) {
    this.store.dispatch(new AddVpnRoutingServer(vpnRoutingServerConfiguration));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  updateVPNRoutingServerStore(vpnRoutingServerConfiguration: VpnRoutingServer) {
    this.store.dispatch(new EditVpnRoutingServer(vpnRoutingServerConfiguration));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  deleteVPNRoutingServers(configurationIds: string[]) {
    this.store.dispatch(new DeleteVpnAndRoutingServer(configurationIds));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  deleteVPNRoutings(configurationIds: string[]) {
    this.store.dispatch(new DeleteVpnAndRouting(configurationIds));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  deleteSplitDnses(splitDnsIds: string[]) {
    this.store.dispatch(new DeleteSplitDns(splitDnsIds));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  deleteStaticRoutings(staticRoutingIds: string[]) {
    this.store.dispatch(new DeleteStaticRouting(staticRoutingIds));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  updateSplitDnsColumnWidth(updatedColumns: type.ColumnWidth[]) {
    this.store.dispatch(new UpdateSplitDnsColumnWidth(updatedColumns));
  }

  updateVpnRoutingColumnWidth(updatedColumns: type.ColumnWidth[]) {
    this.store.dispatch(new UpdateVpnRoutingColumnWidth(updatedColumns));
  }

  updateVpnRoutingServerColumnWidth(updatedColumns: type.ColumnWidth[]) {
    this.store.dispatch(new UpdateVpnRoutingServerColumnWidth(updatedColumns));
  }

  addToStaticRoutingStore(staticRoutingConfiguration: StaticRouting) {
    this.store.dispatch(new AddStaticRouting(staticRoutingConfiguration));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  updateStaticRoutingStore(staticRoutingConfiguration: StaticRouting) {
    this.store.dispatch(new EditStaticRouting(staticRoutingConfiguration));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  updateStaticRoutingColumnWidth(updatedColumns: type.ColumnWidth[]) {
    this.store.dispatch(new UpdateStaticRoutingColumnWidth(updatedColumns));
  }

  updateStaticRoutingDetailColumnWidth(updatedColumns: type.ColumnWidth[]) {
    this.store.dispatch(new UpdateStaticRoutingDetailColumnWidth(updatedColumns));
  }

  updateStaticRoutingRedistribute(value: boolean): void {
    this.store.dispatch(new StaticRoutingRedistributeChange(value));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  addToDNATStore(dnatConfiguration: DNAT) {
    this.store.dispatch(new AddDnat(dnatConfiguration));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  updateDNATStore(dnatConfiguration: DNAT) {
    this.store.dispatch(new EditDnat(dnatConfiguration));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  deleteDnats(dnatIds: string[]) {
    this.store.dispatch(new DeleteDnat(dnatIds));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  addToRemoteSSHStore(remoteSSHConfiguration: RemoteSSH) {
    this.store.dispatch(new AddRemoteSSH(remoteSSHConfiguration));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  updateRemoteSSHStore(remoteSSHConfiguration: RemoteSSH) {
    this.store.dispatch(new EditRemoteSSH(remoteSSHConfiguration));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  deleteRemoteSSHs(remoteSSHIds: string[]) {
    this.store.dispatch(new DeleteRemoteSSH(remoteSSHIds));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  // updateRemoteSSHColumnWidth(updatedColumns: type.ColumnWidth[]) {
  //   this.store.dispatch(new UpdateRemoteSSHColumnWidth(updatedColumns));
  // }

  updateRemoteSSHColumnWidth(updatedColumns: type.ColumnWidth[]) {
    this.store.dispatch(new UpdateRemoteSSHColumnWidth(updatedColumns));
  }

  getCurrentTrafficSteeringRules(): TrafficSteering[] {
    let tsRules: TrafficSteering[] = [];
    this.store
      .select(getCurrentTrafficSteering)
      .pipe(take(1))
      .subscribe((rules: TrafficSteering[]) => {
        tsRules = rules;
      });

    return tsRules;
  }

  getQoSProfiles(): QoSClass[] {
    let qosProfiles = <QoSClass[]>[];
    this.store
      .select(getCurrentDeviceQoSClasses)
      .pipe(take(1))
      .subscribe((classes: QoSClass[]) => {
        qosProfiles = classes;
      });

    return qosProfiles;
  }

  getHardwareInterfaces(): HardwareInterface[] {
    let hardwareInterfaces = <HardwareInterface[]>[];
    this.store
      .select(getCurrentDeviceHardwareInterfaces)
      .pipe(take(1))
      .subscribe((interfaces: HardwareInterface[]) => {
        hardwareInterfaces = interfaces;
      });
    return hardwareInterfaces;
  }

  getTunnelInterfaces(): NetworkInterface[] {
    let tunnelInterfaces = <NetworkInterface[]>[];
    this.store
      .select(getCurrentDeviceTunnelInterfaces)
      .pipe(take(1))
      .subscribe((interfaces: NetworkInterface[]) => {
        tunnelInterfaces = interfaces;
      });
    return tunnelInterfaces;
  }

  getBridgeInterfaces(): BridgeInterface[] {
    let bridgeInterfaces = <BridgeInterface[]>[];
    this.store
      .select(getCurrentDeviceBridgeInterfaces)
      .pipe(take(1))
      .subscribe((interfaces: BridgeInterface[]) => {
        bridgeInterfaces = interfaces;
      });
    return bridgeInterfaces;
  }

  getVlanInterfaces(): VlanInterface[] {
    let vlanInterfaces = <VlanInterface[]>[];
    this.store
      .select(getCurrentDeviceVlanInterfaces)
      .pipe(take(1))
      .subscribe((interfaces: VlanInterface[]) => {
        vlanInterfaces = interfaces;
      });
    return vlanInterfaces;
  }

  getSplitDnses(): SplitDns[] {
    let SplitDnses = <SplitDns[]>[];
    this.store
      .select(getCurrentDeviceSplitDnses)
      .pipe(take(1))
      .subscribe((data: SplitDns[]) => {
        SplitDnses = data;
      });
    return SplitDnses;
  }

  getVpnRoutings(): VpnRouting[] {
    let vpnRoutings = <VpnRouting[]>[];
    this.store
      .select(getCurrentDeviceVpnRoutings)
      .pipe(take(1))
      .subscribe((data: VpnRouting[]) => {
        vpnRoutings = map(data, (vpnRouting) => {
          vpnRouting.lan_subnets = vpnRouting.lan_subnets.toString();
          return vpnRouting;
        });
      });
    return vpnRoutings;
  }

  getVpnRoutingServers(): VpnRoutingServer[] {
    let vpnRoutingServers = <VpnRoutingServer[]>[];
    this.store
      .select(getCurrentDeviceVpnRoutingServers)
      .pipe(take(1))
      .subscribe((data: VpnRoutingServer[]) => {
        vpnRoutingServers = map(data, (vpnRoutingServer) => {
          vpnRoutingServer.lan_subnets = vpnRoutingServer.lan_subnets.toString();
          return vpnRoutingServer;
        });
      });
    return vpnRoutingServers;
  }

  getStaticRouting(): StaticRoutingSaveToCloudReq {
    let StaticRoutings = <StaticRoutingSaveToCloudReq>{};
    this.store
      .select(getCurrentDeviceStaticRoutingsSaveToCloud)
      .pipe(take(1))
      .subscribe((data: StaticRoutingSaveToCloudReq) => {
        StaticRoutings = data;
      });
    return StaticRoutings;
  }

  getDNAT(): DNAT[] {
    let Dnats = <DNAT[]>{};
    this.store
      .select(getCurrentDeviceDnats)
      .pipe(take(1))
      .subscribe((data: DNAT[]) => {
        Dnats = data;
      });
    return Dnats;
  }

  getRemoteSSH(): RemoteSSH[] {
    let RemoteSSHData: RemoteSSH[] = [];
    this.store
      .select(getCurrentDeviceRemoteSSHs)
      .pipe(take(1))
      .subscribe((data: RemoteSSH[]) => {
        RemoteSSHData = data;
      });
    return RemoteSSHData;
  }

  updateQoSColumnWidth(updatedColumns: type.ColumnWidth[]) {
    this.store.dispatch(new UpdateQosColumnWidth(updatedColumns));
  }

  editToQosStore(qosClass: type.QoSClass) {
    this.store.dispatch(new EditQoSClass(qosClass));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  editToHardwareInterfaceStore(hardwareInterface: HardwareInterface) {
    this.store.dispatch(new EditHardwareInterface(hardwareInterface));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  deleteQoSClass(qosClassIds: string[]) {
    this.store.dispatch(new DeleteQoSClass(qosClassIds));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  getParentName(qosClassId: string): string {
    let parentId = '';
    this.store
      .pipe(select(getCurrentDeviceQoSClasses))
      .pipe(take(1))
      .subscribe((qosClasses) => {
        const filteredQoSClass = find(qosClasses, { id: qosClassId });
        if (filteredQoSClass) {
          parentId = `${filteredQoSClass.class_name}/${filteredQoSClass.class_id}`;
        }
      });
    return parentId;
  }

  getNetworkInterface(): MultiSelect[] {
    let networkInterfaces: MultiSelect[] = [];
    this.store.select(getNetworkInterfaces).subscribe((originNetworkInterfaces) => {
      networkInterfaces = originNetworkInterfaces.map((networkInterface) => ({
        id: networkInterface.id,
        value: networkInterface.user_defined_name,
      }));
    });
    return networkInterfaces;
  }

  getWanInterfaces(): MultiSelect[] {
    let wanInterfaces: any[] = [];
    this.store
      .pipe(select(getWANInterfaces))
      .pipe(take(1))
      .subscribe((interfaces) => {
        wanInterfaces = interfaces;
      });
    return wanInterfaces;
  }

  getLanInterfaces(): MultiSelect[] {
    let lanInterfaces: any = [];
    this.store
      .pipe(select(getLANInterfaces))
      .pipe(take(1))
      .subscribe((interfaces) => {
        lanInterfaces = interfaces;
      });
    return lanInterfaces;
  }

  getTUNInterfaces(): MultiSelect[] {
    let tunInterfaces: any[] = [];
    this.store
      .pipe(select(getTUNInterfaces))
      .pipe(take(1))
      .subscribe((interfaces) => {
        tunInterfaces = interfaces;
      });
    return tunInterfaces;
  }

  getBridgeInterfaceNames(): MultiSelect[] {
    let bridgeInterfaces: any = [];
    this.store
      .pipe(select(getBridgeInterfaces))
      .pipe(take(1))
      .subscribe((interfaces) => {
        bridgeInterfaces = interfaces;
      });
    return bridgeInterfaces;
  }

  addToBridgeStore(bridge) {
    this.store.dispatch(new AddBridgeInterface(bridge));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  editToBridgeStore(bridge) {
    this.store.dispatch(new EditBridgeInterface(bridge));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  addToVlanStore(bridge) {
    this.store.dispatch(new AddVlanInterface(bridge));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  editToVlanStore(vlan) {
    this.store.dispatch(new EditVlanInterface(vlan));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  updateMembers(memberIds: string[], bridgeId: string) {
    let interfaceMembers: HardwareInterface[] = [];
    this.store
      .pipe(select(networkInterfaceMembers))
      .pipe(take(1))
      .subscribe((interfaces) => {
        interfaceMembers = interfaces;
      });
    forEach(memberIds, (memberId: string) => {
      const selectedMember: any = find(interfaceMembers, { id: memberId });
      if (selectedMember) {
        selectedMember.bridge_id = bridgeId;
        if (memberId.match(HW_ID_REGEX)) {
          this.store.dispatch(new EditHardwareInterface(selectedMember));
        } else {
          this.store.dispatch(new EditVlanInterface(selectedMember));
        }
      }
    });
  }

  updateBridges(bridgeId: string, interfaceId: string) {
    const payload = {
      bridgeId: bridgeId,
      interfaceId: interfaceId,
    };
    this.store.dispatch(new UpdateBridgeMembers(payload));
  }

  addBridgeMember(bridgeId, interfaceId) {
    const payload = {
      bridgeId: bridgeId,
      interfaceId: interfaceId,
    };
    this.store.dispatch(new AddBridgeMember(payload));
  }

  clearOldMembers(memberIds: string[]) {
    let interfaceMembers: HardwareInterface[] = [];
    this.store
      .pipe(select(networkInterfaceMembers))
      .pipe(take(1))
      .subscribe((interfaces) => {
        interfaceMembers = interfaces;
      });
    forEach(memberIds, (memberId: string) => {
      const selectedMember: any = find(interfaceMembers, { id: memberId });
      if (selectedMember) {
        selectedMember.bridge_id = '';
        if (memberId.match(HW_ID_REGEX)) {
          this.store.dispatch(new EditHardwareInterface(selectedMember));
        } else {
          this.store.dispatch(new EditVlanInterface(selectedMember));
        }
      }
    });
  }

  getHardwareInterfaceNames(): MultiSelect[] {
    let hardwareInterfaces: any[] = [];
    this.store
      .pipe(select(getHardwareInterfaces))
      .pipe(take(1))
      .subscribe((interfaces) => {
        hardwareInterfaces = interfaces;
      });
    return hardwareInterfaces;
  }

  getNetworkInterfaces() {
    let networkInterfaces: HardwareInterface[] = [];
    this.store
      .pipe(select(networkInterfaceMembers))
      .pipe(take(1))
      .subscribe((interfaces) => {
        networkInterfaces = interfaces;
      });
    return networkInterfaces;
  }

  fetchHardwareInterfaces() {
    let hardwareInterfaces: HardwareInterface[] = [];
    this.store
      .pipe(select(getCurrentDeviceHardwareInterfaces))
      .pipe(take(1))
      .subscribe((interfaces) => {
        hardwareInterfaces = interfaces;
      });
    return hardwareInterfaces;
  }

  fetchBridgeInterfaces() {
    let bridgeInterfaces: BridgeInterface[] = [];
    this.store
      .pipe(select(getCurrentDeviceBridgeInterfaces))
      .pipe(take(1))
      .subscribe((interfaces) => {
        bridgeInterfaces = interfaces;
      });
    return bridgeInterfaces;
  }

  getMappedBridges(bridgeId: string, bridgeName: string) {
    let mappedBridges: MappedInterfaces[] = [];
    this.store
      .pipe(select(getMappedBridge, { bridgeId: bridgeId, bridgeName: bridgeName }))
      .pipe(take(1))
      .subscribe((interfaceStatus) => {
        mappedBridges = interfaceStatus;
      });
    return mappedBridges;
  }

  getMappedVlans(vlanId: string, vlanName: string) {
    let mappedVlans: MappedInterfaces[] = [];
    this.store
      .pipe(select(getMappedVlan, { memberId: vlanId, vlanName: vlanName }))
      .pipe(take(1))
      .subscribe((vlanStatus) => {
        mappedVlans = vlanStatus;
      });
    return mappedVlans;
  }

  deleteBridge(bridgeId: string) {
    this.store.dispatch(new DeleteBridgeMember(bridgeId));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  deleteVlan(vlanId: string) {
    this.store.dispatch(new DeleteVlanInterface(vlanId));
    this.store.dispatch(new SetLocallyChangedField(true));
  }

  getIpsecServerNamesWithoutCurrentDevice(
    organizationId: string,
    deviceId: string
  ): Observable<IpsecServerIdNames[]> {
    return this.getIpsecServers(organizationId).pipe(
      rxjsMap((response: IpsecServersResponse[]) => {
        let serversToReturn: IpsecServerIdNames[] = [];
        response.forEach((server: IpsecServersResponse) => {
          if (server.device_id !== deviceId) {
            const ipsecServer = server.server_name.map((serverName) => ({
              id: parseInt(server.device_id),
              name: serverName,
            }));
            serversToReturn = serversToReturn.concat(ipsecServer);
          }
        });
        return serversToReturn;
      })
    );
  }

  private getIpsecServers(organizationId: string): Observable<IpsecServersResponse[]> {
    return this.httpClient.get<IpsecServersResponse[]>(
      `${this.apiUrl}/v1/ipsec/${organizationId}/servers`
    );
  }

  getIpsecClients(organizationId: number, serverName: string): Observable<IpsecClients[]> {
    const params = new HttpParams().append('server_name', serverName);
    return this.httpClient.get<IpsecClients[]>(
      `${this.apiUrl}/v1/ipsec/${organizationId}/clients`,
      {
        params,
      }
    );
  }

  restartIpSecClients(deviceId: string | number): Observable<any> {
    return this.httpClient.put<any>(`${this.apiUrl}/v1/ipsec/${deviceId}/restart_clients`, null);
  }

  capturePackets(deviceId: string, params: PacketCaptureRequestPrams): Observable<Message> {
    return this.httpClient.post<Message>(
      `${this.apiUrl}/v1/devices/${deviceId}/packets_capture`,
      params
    );
  }

  getCapturePacketsStatus(deviceId: string): Observable<PacketCaptureStatusResponse> {
    return interval(10000).pipe(
      startWith(0),
      switchMap(() =>
        this.httpClient.get<PacketCaptureStatusResponse>(
          `${this.apiUrl}/v1/devices/${deviceId}/packet_capture_status`
        )
      ),
      takeWhile((response) => !this.isPacketCaptureEnded(response.status), true)
    );
  }

  isPacketCaptureEnded(status: PACKET_CAPTURE_STATUS): boolean {
    return [
      PACKET_CAPTURE_STATUS.COMPLETED,
      PACKET_CAPTURE_STATUS.ERRORED,
      PACKET_CAPTURE_STATUS.TIME_OUT,
    ].includes(status);
  }

  isPacketCaptureStopping(status: PACKET_CAPTURE_STATUS): boolean {
    return [
      PACKET_CAPTURE_STATUS.STOP_REQUESTING,
      PACKET_CAPTURE_STATUS.STOP_REQUESTED,
      PACKET_CAPTURE_STATUS.STOPPING,
      PACKET_CAPTURE_STATUS.STOPPED,
    ].includes(status);
  }

  downloadPacketCaptureData(deviceId: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(
      `${this.apiUrl}/v1/devices/${deviceId}/download_packet_capture`,
      {
        responseType: 'blob' as 'json',
        observe: 'response',
      }
    );
  }

  stopPacketCapturing(deviceId: string): Observable<Message> {
    return this.httpClient.post<Message>(
      `${this.apiUrl}/v1/devices/${deviceId}/stop_packet_capture`,
      null
    );
  }
}
