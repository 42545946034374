import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as OrganizationsActions from '@secure/organizations/store/actions/organizations.actions';
import { DEVICE_STATE, INTERFACE_STATUS } from '@shared/constants/constants';
import { Permissions } from '@shared/constants/permission.constant';
import { NavigationService } from '@shared/services/custom-navigation.service';
import { GlobalLanguageService } from '@shared/services/global-language.service';
import { IntervalService } from '@shared/services/interval.service';
import { SharedService } from '@shared/services/shared.service';
import { StoreState } from '@views/secure/devices/store/models/devices.model';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import { isUndefined, orderBy, sortBy } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { OrganizationsService } from '../services/organizations.service';
import { Device, DeviceListType, OrganizationDetail } from '../store/models/organizations.model';

const FETCH_INTERVAL_DURATION = 10000;

@Component({
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit, AfterViewInit, OnDestroy {
  isPageLoading = true;
  organization: OrganizationDetail;
  devices: Device[];
  devicesList: Device[];
  activeDevices: Device[] = [];
  offlineDevices: Device[] = [];
  notProvisionedDevices: Device[] = [];
  wanUpDevices: Device[] = [];
  wanDownDevices: Device[] = [];
  deviceListTypes = DeviceListType;
  activeDeviceList = this.deviceListTypes.deviceList;
  isTableView: boolean;
  canUpdateOrganization = Permissions.updateOrganization;
  lastSynced = new Date();
  initialRendering: boolean = true;
  gridApi: any;
  columnApi: any;
  private map: L.Map;
  private markerLayersClusterGroup: L.MarkerClusterGroup = L.markerClusterGroup();
  private id: number;
  private subscriptions = new Subscription();
  isCollapsed = true;
  showBack = true;
  currentPlotDeviceMarkersType;
  markerClusterClicked = false;
  isSuperAdmin: boolean;
  deviceColumnDefs = [
    { field: 'location', headerName: this.localizeHeader('DEVICES.LIST.LOCATION') },
    { field: 'name', headerName: this.localizeHeader('DEVICES.LIST.NAME') },
    { field: 'uuid', headerName: this.localizeHeader('DEVICES.LIST.UUID') },
    {
      field: 'is_online',
      headerName: this.localizeHeader('DEVICES.LIST.WEBSOCKET'),
      valueGetter: (params) => (params.data.is_online ? 'Online' : 'Offline'),
    },
    { field: 'type', headerName: this.localizeHeader('DEVICES.LIST.TYPE') },
    { field: 'state', headerName: this.localizeHeader('DEVICES.LIST.STATE') },
  ];

  wanColumnDefs = [
    {
      field: 'device_name',
      headerName: this.localizeHeader('DEVICES.WANLIST.DEVICE_NAME'),
      resizable: true,
    },
    {
      field: 'user_defined_name',
      headerName: this.localizeHeader('DEVICES.WANLIST.USER_DEFINED_NAME'),
      resizable: true,
    },
    {
      field: 'isp_name',
      headerName: this.localizeHeader('DEVICES.WANLIST.ISP_NAME'),
      resizable: true,
    },
    {
      field: 'isp_link_speed_in_mbps',
      headerName: this.localizeHeader('DEVICES.WANLIST.ISP_LINK_SPEED'),
      resizable: true,
    },
    {
      field: 'latency',
      headerName: this.localizeHeader('DEVICES.WANLIST.LATENCY'),
      width: 80,
      resizable: true,
    },
    {
      field: 'tx_throughput_in_bytes',
      headerName: this.localizeHeader('DEVICES.WANLIST.TX_THROUGHPUT'),
      resizable: true,
    },
    {
      field: 'rx_throughput_in_bytes',
      headerName: this.localizeHeader('DEVICES.WANLIST.RX_THROUGHPUT'),
      resizable: true,
    },

    {
      field: 'tx_utilization_in_percentage',
      headerName: this.localizeHeader('DEVICES.WANLIST.TX_UTILIZATION'),
      resizable: true,
    },
    {
      field: 'rx_utilization_in_percentage',
      headerName: this.localizeHeader('DEVICES.WANLIST.RX_UTILIZATION'),
      resizable: true,
    },
  ];

  columnDefs = this.deviceColumnDefs;

  private readonly onlineIcon = L.icon({
    iconUrl: 'assets/img/icons/location-green.png',
    iconSize: [30, 30],
    iconAnchor: [12, 41],
    tooltipAnchor: [16, -28],
  });

  private readonly offlineIcon = L.icon({
    iconUrl: 'assets/img/icons/location-red.png',
    iconSize: [30, 30],
    iconAnchor: [12, 41],
    tooltipAnchor: [16, -28],
  });

  constructor(
    private navigationService: NavigationService,
    private router: Router,
    private route: ActivatedRoute,
    private organizationService: OrganizationsService,
    private _intervalService: IntervalService,
    public toast: ToastrService,
    public store: Store<StoreState>,
    private globalTranslate: GlobalLanguageService,
    public sharedService: SharedService,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.isSuperAdmin = this.sharedService.isSuperAdmin;
    if (!this.organization) {
      this.fetchOrganizationDetail();
    }
    this.periodicallyFetch();
    const navHistory = this.navigationService.getHistory();
    this.showBack = navHistory[navHistory.length - 2] !== '/verifyotp';
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnDestroy(): void {
    this.elementRef.nativeElement.querySelector('#map')?.remove();
    this.subscriptions.unsubscribe();
    this._intervalService.clearInterval();
  }

  fetchOrganizationDetail() {
    this.getCurrentOrganization();
    this.getCurrentOrganizationWan();
  }

  periodicallyFetch() {
    this._intervalService.setInterval(FETCH_INTERVAL_DURATION, () =>
      this.fetchOrganizationDetail()
    );
  }

  goBack(): void {
    this.navigationService.back();
  }

  goToEdit(): void {
    this.store.dispatch(new OrganizationsActions.SetEditOrganization(this.organization));
    this.router.navigate(['/organizations', this.id, 'edit']);
  }

  gotoDetailView(params: Device): void {
    const id = params.id;
    this.router.navigate([`/devices`, id]);
  }

  private getCurrentOrganization(): void {
    this.organizationService.getCurrentOrganization(this.id).subscribe(
      (organization) => {
        this.organization = organization;
        this.devices = organization.devices;
        this.isPageLoading = false;
        this.setDevicesStateWise();
        if (isUndefined(this.currentPlotDeviceMarkersType)) {
          this.plotDeviceMarkers(DeviceListType.deviceList);
        } else {
          this.plotDeviceMarkers(this.currentPlotDeviceMarkersType);
        }

        this.devices = orderBy(organization.devices, ['state'], ['desc']);
      },
      (error) => {
        this.toast.error(error.message);
        this.isPageLoading = false;
      }
    );
  }

  private getCurrentOrganizationWan() {
    const wanUpDevices: any[] = [];
    const wanDownDevices: any[] = [];
    this.organizationService.getCurrentOrganizationWanDetails(this.id).subscribe(
      (wanDetails) => {
        wanDetails.forEach((currentWan) => {
          this.lastSynced = new Date();
          if (currentWan.wan_status === INTERFACE_STATUS.UP) {
            wanUpDevices.push(currentWan);
          } else {
            wanDownDevices.push(currentWan);
          }
        });
        this.wanUpDevices = sortBy(wanUpDevices, ['device_name', 'user_defined_name']);
        this.wanDownDevices = sortBy(wanDownDevices, ['device_name', 'user_defined_name']);
      },
      (error) => {
        this.toast.error(error.message);
        this.isPageLoading = false;
      }
    );
  }

  private setDevicesStateWise(): void {
    const activeDevices: Device[] = [];
    const offlineDevices: Device[] = [];
    const notProvisionedDevices: Device[] = [];
    this.devices.forEach((device: Device) => {
      if (this.isDeviceOnlineAndNotDeleted(device)) {
        activeDevices.push(device);
      } else {
        offlineDevices.push(device);
      }
      if (device.state === DEVICE_STATE.NOT_PROVISIONED) {
        notProvisionedDevices.push(device);
      }
    });
    this.activeDevices = activeDevices;
    this.offlineDevices = offlineDevices;
    this.notProvisionedDevices = notProvisionedDevices;
  }

  private isDeviceOnlineAndNotDeleted(device: Device): boolean {
    return (
      device.is_online &&
      device.state !== DEVICE_STATE.BEING_DELETED &&
      device.state !== DEVICE_STATE.DELETED
    );
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [20.5937, 78.9629],
      zoom: 4,
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);
  }

  public plotDeviceMarkers(type): void {
    if (this.map) {
      this.currentPlotDeviceMarkersType = type;
      this.activeDeviceList = type;

      if (!this._intervalService.getInterval()) {
        this.periodicallyFetch();
      }

      switch (type) {
        case DeviceListType.deviceList:
          this.devicesList = this.devices;
          this.columnDefs = this.deviceColumnDefs;
          break;
        case DeviceListType.activeDeviceList:
          this.devicesList = this.activeDevices;
          this.columnDefs = this.deviceColumnDefs;
          break;
        case DeviceListType.offlineDeviceList:
          this.devicesList = this.offlineDevices;
          this.columnDefs = this.deviceColumnDefs;
          break;
        case DeviceListType.notProvisionedDeviceList:
          this.devicesList = this.notProvisionedDevices;
          this.columnDefs = this.deviceColumnDefs;
          break;
        case DeviceListType.wanUpDeviceList:
          this.devicesList = this.wanUpDevices;
          this.columnDefs = this.wanColumnDefs;
          break;
        case DeviceListType.wanDownDeviceList:
          this.devicesList = this.wanDownDevices;
          this.columnDefs = this.wanColumnDefs;
          break;
      }

      this.markerLayersClusterGroup.clearLayers();
      this.devicesList.forEach((device: any) => {
        const marker = L.marker([device.latitude, device.longitude], {
          icon:
            this.isDeviceOnlineAndNotDeleted(device) || device.runtime_status === 'UP'
              ? this.onlineIcon
              : this.offlineIcon,
        }).on('click', () => {
          const deviceId = device.id || device.device_id;
          this.router.navigate(['/devices', deviceId, 'monitoring']);
        });
        marker.bindTooltip(this.getDeviceTooltip(device));
        this.markerLayersClusterGroup.addLayer(marker);
      });

      if (!this.markerClusterClicked) {
        this.markerLayersClusterGroup.on('clusterclick', () => {
          this.markerClusterClicked = true;
          if (this._intervalService.getInterval()) {
            this._intervalService.clearInterval();
          }
        });
      }
      this.map.addLayer(this.markerLayersClusterGroup);
      if (this.initialRendering) {
        this.initialRendering = false;
        this.map?.fitBounds(this.markerLayersClusterGroup.getBounds());
      }
      this.sizeToFit();
    }
  }

  public onToggleView(): void {
    this.isTableView = !this.isTableView;
  }

  private getDeviceTooltip(device: Device): L.Tooltip {
    let content = '';
    if (device.name) {
      content = content.concat(
        `<div>${this.localizeHeader('DEVICES.LIST.NAME')}:${device.name}</div>`
      );
    }
    if (device.uuid) {
      content = content.concat(
        `<div>${this.localizeHeader('DEVICES.LIST.UUID')}:${device.uuid}</div>`
      );
    }
    if (device.is_online) {
      content = content.concat(
        `<div>${this.localizeHeader('DEVICES.LIST.WEBSOCKET')}:${device.is_online}</div>`
      );
    }
    if (device.type) {
      content = content.concat(
        `<div>${this.localizeHeader('DEVICES.LIST.TYPE')}:${device.type}</div>`
      );
    }
    if (device.state) {
      content = content.concat(
        `<div>${this.localizeHeader('DEVICES.LIST.STATE')}:${device.state}</div>`
      );
    }
    return L.tooltip().setContent(content);
  }

  public localizeHeader(params: string): string {
    return this.globalTranslate.getTranslation(params);
  }

  public onToggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }
}
