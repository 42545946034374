import { Action } from '@ngrx/store';
import { DeviceConfiguration } from '@secure/devices/store/models/device-configuration.model';

export enum ActionTypes {
  SET_CONIG_BY_DEVICE_ID = 'SET_CONFIG_DEVICE_BY_ID',
  SET_LOCALLY_CHANGED_FIELD = 'SET_LOCALLY_CHANGED_FIELD',
  SET_CONFIGURATION_VERSION = 'SET_CONFIGURATION_VERSION',
  SET_VERSION_CHANGED = 'SET_VERSION_CHANGED',
}
export class SetDeviceConfigurationById implements Action {
  readonly type = ActionTypes.SET_CONIG_BY_DEVICE_ID;
  constructor(public payload: DeviceConfiguration) {}
}

export class SetLocallyChangedField implements Action {
  readonly type = ActionTypes.SET_LOCALLY_CHANGED_FIELD;
  constructor(public payload: boolean) {}
}

export class SetDeviceVersion implements Action {
  readonly type = ActionTypes.SET_CONFIGURATION_VERSION;
  constructor(public payload: number) {}
}

export class SetVersionChanged implements Action {
  readonly type = ActionTypes.SET_VERSION_CHANGED;
  constructor(public payload: boolean) {}
}

export type Actions =
  | SetDeviceConfigurationById
  | SetLocallyChangedField
  | SetDeviceVersion
  | SetVersionChanged;
