import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Columns, DeleteResponse } from '@shared/models/shared.model';
import { StoreState } from 'app/store/store';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import * as organizationsActions from '../store/actions/organizations.actions';
import {
  Organization,
  OrganizationColumns,
  OrganizationDetail,
  OrganizationFields,
  OrganizationParams,
  OrganizationsResponse,
} from '../store/models/organizations.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  private readonly url = environment.baseUrl;
  private page: number;
  private globalSearch: string;
  private columnSearch: OrganizationColumns;
  private sortedColumns: any;

  constructor(
    public store: Store<StoreState>,
    private http: HttpClient,
    public toast: ToastrService
  ) {
    this.store.pipe(select('organizations')).subscribe((val) => {
      this.page = val.pagination.current_page;
      this.globalSearch = val.globalSearch;
      this.columnSearch = val.columns;
      this.sortedColumns = val.shuffledColumns;
    });
  }

  fetchOrganizations(gridApi) {
    gridApi.showLoadingOverlay();
    let params = new HttpParams();
    params = params.append('page', this.page.toString());
    params = params.append('q', this.globalSearch);

    for (const column in this.columnSearch) {
      if (this.columnSearch[column]) {
        params = params.append(`${column}`, this.columnSearch[column]);
      }
    }

    const visibleColumns: string[] = [];
    this.sortedColumns.forEach((column: Columns) => {
      if (!column.hide) {
        visibleColumns.push(column.field);
      }
      if (column.sort !== '') {
        params = params.append(`o_${column.field}`, column.sort.toUpperCase());
      }
    });

    params = params.append('visible_columns', visibleColumns.toString());

    this.http
      .get<OrganizationsResponse>(`${this.url}/v1/organizations`, { params })
      .pipe(first())
      .subscribe(
        (data) => {
          const organizationsResponse: OrganizationsResponse = {
            organizations: data.organizations,
            pagination: data.pagination,
          };
          this.store.dispatch(new organizationsActions.FetchOrganizations(organizationsResponse));
          gridApi.hideOverlay();
        },
        (error) => {
          this.toast.error(error.message);
          gridApi.hideOverlay();
        }
      );
  }

  addOrganization(organization: OrganizationFields): Observable<Organization> {
    const params: OrganizationParams = {
      organization: organization,
    };
    return this.http.post<Organization>(`${this.url}/v1/organizations`, params);
  }

  editOrganization(id: number, organization: OrganizationFields): Observable<Organization> {
    const params: OrganizationParams = {
      organization: organization,
    };
    return this.http.put<Organization>(`${this.url}/v1/organizations/${id}`, params);
  }

  deleteOrganizations(idsToDelete: string): Observable<DeleteResponse> {
    const params = {
      ids: idsToDelete,
    };
    return this.http.delete<DeleteResponse>(`${this.url}/v1/organizations`, { params });
  }

  getCurrentOrganization(id: number): Observable<OrganizationDetail> {
    return this.http.get<OrganizationDetail>(`${this.url}/v1/organizations/${id}`);
  }

  getCurrentOrganizationWanDetails(id: number): Observable<any> {
    return this.http.get<any>(`${this.url}/v1/organizations/${id}/wan_details`);
  }
}
