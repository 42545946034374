<div class="animated fadeIn">
  <div class="row mb-1">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="row mb-1">
            <div class="col-3 text-left">
              <button
                size="sm"
                class="btn btn-primary"
                (click)="goBack()"
                data-e2eid="back"
                *ngIf="showBack"
              >
                <i class="fa fa-chevron-left"></i>&nbsp;
                <span class="btn-text" translate>SHARED.BACK</span>
              </button>
            </div>
            <div class="col-6 text-center">
              <h4 *ngIf="isSuperAdmin">
                {{ organization?.name }}
                <span translate> ORGANIZATIONS.DETAIL.PAGETITLE </span>
              </h4>
              <h4 *ngIf="!isSuperAdmin" translate>ORGANIZATIONS.DETAIL.DASHBOARD</h4>
            </div>
            <div class="col-3 text-right">
              <button
                *appPermission="canUpdateOrganization"
                size="sm"
                class="btn btn-primary"
                (click)="goToEdit()"
                data-e2eid="edit"
              >
                <span class="btn-text" translate>SHARED.EDIT</span>
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex text-center mt-2 p-2 device">
          <div class="d-flex p-2 bg-grey">
            <div class="total-devices-container">
              <div
                class="rounded d-flex align-items-center p-1"
                [class.bg-active]="activeDeviceList === deviceListTypes.deviceList"
                [class.cursor-not-allowed]="!devices?.length"
                (click)="devices.length ? plotDeviceMarkers(deviceListTypes.deviceList) : false"
              >
                <img
                  [src]="
                    activeDeviceList === deviceListTypes.deviceList
                      ? 'assets/img/icons/total_devices_active.svg'
                      : 'assets/img/icons/total_devices.svg'
                  "
                  alt="first"
                  width="30"
                  height="30"
                />
                <div class="text-left mr-2" [class.cursor-not-allowed]="!devices?.length">
                  <p class="m-0">
                    <b>{{ devices?.length }}</b>
                  </p>
                  <p class="m-0 device_type">Total Devices</p>
                </div>
                <i class="fa fa-solid fa-angle-right"></i>
              </div>
            </div>

            <div class="online-devices-container">
              <div
                class="rounded d-flex align-items-center p-1"
                [class.bg-active]="activeDeviceList === deviceListTypes.activeDeviceList"
                [class.cursor-not-allowed]="!activeDevices.length"
                (click)="
                  activeDevices.length ? plotDeviceMarkers(deviceListTypes.activeDeviceList) : false
                "
              >
                <img
                  [src]="
                    activeDeviceList === deviceListTypes.activeDeviceList
                      ? 'assets/img/icons/online_devices_active.svg'
                      : 'assets/img/icons/online_devices.svg'
                  "
                  alt="first"
                  width="30"
                  height="30"
                />
                <div class="text-left mr-2" [class.cursor-not-allowed]="!activeDevices.length">
                  <p class="m-0">
                    <b>{{ activeDevices?.length }}</b>
                  </p>
                  <p class="m-0 device_type">Online Devices</p>
                </div>
                <i class="fa fa-solid fa-angle-right"></i>
              </div>
            </div>

            <div class="offline-devices-container">
              <div
                class="rounded d-flex align-items-center p-1"
                [class.bg-active]="activeDeviceList === deviceListTypes.offlineDeviceList"
                [class.cursor-not-allowed]="!offlineDevices.length"
                (click)="
                  offlineDevices.length
                    ? plotDeviceMarkers(deviceListTypes.offlineDeviceList)
                    : false
                "
              >
                <img
                  [src]="
                    activeDeviceList === deviceListTypes.offlineDeviceList
                      ? 'assets/img/icons/offline_devices_active.svg'
                      : 'assets/img/icons/offline_devices.svg'
                  "
                  alt="first"
                  width="30"
                  height="30"
                />
                <div class="text-left mr-2" [class.cursor-not-allowed]="!offlineDevices.length">
                  <p class="m-0">
                    <b>{{ offlineDevices?.length }}</b>
                  </p>
                  <p class="m-0 device_type">Offline Devices</p>
                </div>
                <i class="fa fa-solid fa-angle-right"></i>
              </div>
            </div>
          </div>

          <div class="vertical-line"></div>

          <div
            class="not-provisioned-devices-container rounded"
            [class.bg-active]="activeDeviceList === deviceListTypes.notProvisionedDeviceList"
          >
            <div
              class="d-flex align-items-center p-1 mt-1"
              [class.cursor-not-allowed]="!notProvisionedDevices.length"
              (click)="
                notProvisionedDevices?.length
                  ? plotDeviceMarkers(deviceListTypes.notProvisionedDeviceList)
                  : false
              "
            >
              <img
                [src]="
                  activeDeviceList === deviceListTypes.notProvisionedDeviceList
                    ? 'assets/img/icons/not_provisioned_active.svg'
                    : 'assets/img/icons/not_provisioned.svg'
                "
                alt="first"
                width="30"
                height="30"
              />
              <div
                class="text-left mr-2"
                [class.cursor-not-allowed]="!notProvisionedDevices.length"
              >
                <p class="m-0">
                  <b>{{ notProvisionedDevices?.length }}</b>
                </p>
                <p class="m-0 device_type">Not Provisioned</p>
              </div>
              <i class="fa fa-solid fa-angle-right"></i>
            </div>
          </div>

          <div
            class="wan-up-container rounded"
            [class.bg-active]="activeDeviceList === deviceListTypes.wanUpDeviceList"
          >
            <div
              class="d-flex align-items-center p-1 mt-1"
              [class.cursor-not-allowed]="!wanUpDevices.length"
              (click)="
                wanUpDevices.length ? plotDeviceMarkers(deviceListTypes.wanUpDeviceList) : false
              "
            >
              <img src="assets/img/icons/wan_up.svg" alt="first" width="30" height="30" />
              <div class="text-left mr-2" [class.cursor-not-allowed]="!wanUpDevices.length">
                <p class="m-0">
                  <b>{{ wanUpDevices?.length }}</b>
                </p>
                <p class="m-0 device_type">WAN Up</p>
              </div>
              <i class="fa fa-solid fa-angle-right"></i>
            </div>
          </div>

          <div
            class="wan-down-container rounded"
            [class.bg-active]="activeDeviceList === deviceListTypes.wanDownDeviceList"
          >
            <div
              class="d-flex align-items-center p-1 mt-1"
              [class.cursor-not-allowed]="!wanDownDevices.length"
              (click)="
                wanDownDevices.length ? plotDeviceMarkers(deviceListTypes.wanDownDeviceList) : false
              "
            >
              <img src="assets/img/icons/wan_down.svg" alt="first" width="30" height="30" />
              <div class="text-left mr-2" [class.cursor-not-allowed]="!wanDownDevices.length">
                <p class="m-0">
                  <b>{{ wanDownDevices?.length }}</b>
                </p>
                <p class="m-0 device_type">WAN Down</p>
              </div>
              <i class="fa fa-solid fa-angle-right"></i>
            </div>
          </div>

          <div class="d-flex ml-auto align-self-center">
            <button class="btn btn-primary" (click)="onToggleView()">
              <i [class]="isTableView ? 'fa fa-map' : 'fa fa-table'" aria-hidden="true"></i>
              <span
                class="ml-1"
                [innerText]="
                  localizeHeader(
                    isTableView
                      ? 'ORGANIZATIONS.DETAIL.MAP_VIEW'
                      : 'ORGANIZATIONS.DETAIL.TABLE_VIEW'
                  )
                "
                translate
              ></span>
            </button>
          </div>
        </div>

        <div class="card-header" *ngIf="isTableView">
          <app-data-grid
            style="width: 100%"
            class="ag-theme-alpine"
            [rowData]="devicesList"
            [columnDefs]="columnDefs"
            [pagination]="true"
            [paginationPageSize]="15"
            (rowClicked)="gotoDetailView($event)"
          ></app-data-grid>
        </div>
        <div class="mt-2 p-2" [class.d-none]="isTableView">
          <div id="map"></div>
        </div>

        <div class="card-header" *ngIf="!isPageLoading">
          <div class="d-flex justify-content-between">
            <h4 translate>ORGANIZATIONS.DETAIL.DETAIL</h4>
            <button
              class="btn btn-primary"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOrganizationDetail"
              aria-expanded="true"
              aria-controls="collapseOrganizationDetail"
              (click)="onToggleCollapse()"
            >
              <i
                [class]="isCollapsed ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"
                aria-hidden="true"
              ></i>
            </button>
          </div>
          <div class="collapse show" id="collapseOrganizationDetail">
            <div class="row mb-1">
              <div class="col-4 text-left">
                <b translate>ORGANIZATIONS.DETAIL.NAME</b>
              </div>
              <div class="col-8 text-left">
                {{ organization.name }}
              </div>
            </div>
            <div class="row mb-1" *ngIf="organization.uuid">
              <div class="col-4 text-left">
                <b translate>ORGANIZATIONS.DETAIL.UUID</b>
              </div>
              <div class="col-8 text-left">
                {{ organization.uuid }}
              </div>
            </div>
            <div class="row mb-1" *ngIf="organization.description">
              <div class="col-4 text-left">
                <b translate>ORGANIZATIONS.DETAIL.DESCRIPTION</b>
              </div>
              <div class="col-8 text-left">
                {{ organization.description }}
              </div>
            </div>
            <div class="row mb-1" *ngIf="organization.client_name">
              <div class="col-4 text-left">
                <b translate>ORGANIZATIONS.DETAIL.CLIENT_NAME</b>
              </div>
              <div class="col-8 text-left">
                {{ organization.client_name }}
              </div>
            </div>
            <div class="row mb-1" *ngIf="organization.client_mobile_no">
              <div class="col-4 text-left">
                <b translate>ORGANIZATIONS.DETAIL.CLIENT_MOBILE_NO</b>
              </div>
              <div class="col-8 text-left">
                {{ organization.client_mobile_no }}
              </div>
            </div>
            <div class="row mb-1" *ngIf="organization.client_email">
              <div class="col-4 text-left">
                <b translate>ORGANIZATIONS.DETAIL.CLIENT_EMAIL</b>
              </div>
              <div class="col-8 text-left">
                {{ organization.client_email }}
              </div>
            </div>
            <div class="row mb-1" *ngIf="organization.client_address">
              <div class="col-4 text-left">
                <b translate>ORGANIZATIONS.DETAIL.CLIENT_ADDRESS</b>
              </div>
              <div class="col-8 text-left">
                {{ organization.client_address }}
              </div>
            </div>
            <div class="row mb-1" *ngIf="organization.description">
              <div class="col-4 text-left">
                <b translate>ORGANIZATIONS.DETAIL.LAST_SYNCED</b>
              </div>
              <div class="col-8 text-left">
                {{ lastSynced | date: 'medium' }}
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3 px-2">
          <app-helper-page [gitPath]="'organization/detail.md'"></app-helper-page>
        </div>
      </div>
    </div>
  </div>
</div>
