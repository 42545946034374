<app-header
  [navbarBrandRouterLink]="['/devices']"
  [fixed]="true"
  [navbarBrandFull]="{
    src: 'assets/img/brand/logo.svg',
    width: 89,
    height: 25,
    alt: 'ChiefNet Logo',
  }"
  [navbarBrandMinimized]="{
    src: 'assets/img/brand/brandLogo.svg',
    width: 30,
    height: 30,
    alt: 'ChiefNet Logo',
  }"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="false"
  [mobileAsideMenuToggler]="false"
>
  <div class="mx-auto">
    <h2 class="text-center">
      <span translate> NAVIGATION.TITLE </span>
      <span>({{ isSuperAdmin ? ('NAVIGATION.SUPER_ADMIN' | translate) : organizationName }})</span>
    </h2>
  </div>

  <ul class="nav navbar-nav ml-auto gap-2">
    <li
      class="nav-item dropdown"
      dropdown
      placement="bottom right"
      *appPermission="notificationPermission"
    >
      <div>
        <a
          class="nav-link fs-6"
          data-toggle="dropdown"
          href="#"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          dropdownToggle
          (click)="(false)"
        >
          <i class="icon-bell font-weight-bold"></i
          ><span class="badge badge-pill badge-danger" *ngIf="this.notificationCount">{{
            this.notificationCount > 99 ? '99+' : this.notificationCount
          }}</span>
        </a>
      </div>
      <div
        class="dropdown-menu dropdown-menu-right dropdown-menu-notification"
        *dropdownMenu
        aria-labelledby="simple-dropdown"
        (click)="onDropDownMenuClick($event)"
      >
        <div class="d-flex justify-content-between">
          <h4 class="mb-0" translate>NOTIFICATIONS.NOTIFICATIONS.NOTIFICATIONS</h4>
          <img
            class="cursor-pointer"
            src="/assets/img/icons/settings.svg"
            alt="notification settings icon"
            routerLink="settings"
            (click)="setAllowToPropagate()"
          />
        </div>
        <hr />
        <div *ngIf="notifications?.length; else noNotificationBlock">
          <button
            type="button"
            class="btn btn-link pl-0 pt-0 mb-3"
            (click)="onMarkAllAsRead()"
            translate
          >
            SHARED.MARK_ALL_AS_READ
          </button>
          <div
            *ngFor="let notification of notifications"
            [class.unread]="notification.has_read"
            class="div-notification mt-2 unread"
          >
            <div class="d-flex gap-2" (click)="onMarkAsRead(notification)">
              <div>
                <div class="div-icon d-flex justify-content-center align-items-center">
                  <div class="div-child d-flex justify-content-center align-items-center">
                    <img
                      width="22"
                      height="22"
                      [src]="getNotificationIconLink(notification.event_type)"
                      alt="Notification icon"
                    />
                  </div>
                </div>
              </div>
              <div class="pl-2 w-100">
                <h6>{{ notification.message }}</h6>
              </div>
              <div class="div-unread text-right">
                <i class="fa fa-circle text-primary" aria-hidden="true"></i>
              </div>
            </div>
            <p
              class="text-right mb-1"
              [tooltip]="notification.created_at | date: 'short'"
              placement="auto"
            >
              {{ getRelativeTime(notification.created_at) }}
            </p>
          </div>
          <div class="text-center p-2">
            <button
              type="button"
              class="btn btn-primary px-4"
              routerLink="notifications"
              translate
              (click)="setAllowToPropagate()"
            >
              NOTIFICATIONS.NOTIFICATIONS.VIEW_MORE
            </button>
          </div>
        </div>
        <ng-template #noNotificationBlock>
          <div class="text-center">
            <div class="d-flex justify-content-center">
              <div class="d-flex justify-content-center align-items-center div-wave rounded-circle">
                <img width="56" height="56" src="assets/img/icons/wave.svg" alt="wave" />
              </div>
            </div>
            <h5 class="mt-5" translate>NOTIFICATIONS.NOTIFICATIONS.NO_NOTIFICATION</h5>
            <p class="mb-4" translate>NOTIFICATIONS.NOTIFICATIONS.NO_NOTIFICATION_SUB_TITLE</p>
          </div>
        </ng-template>
      </div>
    </li>
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <ng-template #template>
        <h6 translate>NAVIGATION.ACCOUNT</h6>
        <li>{{ userData.name }}</li>
      </ng-template>

      <div [tooltip]="template" placement="auto">
        <a
          class="nav-link"
          data-toggle="dropdown"
          href="#"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          dropdownToggle
          (click)="(false)"
        >
          <ngx-avatars
            class="d-flex justify-content-center"
            [name]="userData.name"
            [gravatarId]="userData.email"
            size="40"
          ></ngx-avatars>
        </a>
      </div>

      <div
        class="dropdown-menu dropdown-menu-right"
        *dropdownMenu
        aria-labelledby="simple-dropdown"
      >
        <div class="dropdown-header text-center text-wrap" translate>
          NAVIGATION.ACCOUNT <br />
          <strong>{{ userData.name }}</strong>
        </div>
        <a class="dropdown-item" [routerLink]="['/users/changepassword']" translate
          ><i class="fa fa-shield"></i>NAVIGATION.CHANGE_PASSWORD</a
        >
        <a class="dropdown-item pointer" (click)="signOutUser()" translate
          ><i class="fa fa-sign-out"></i>NAVIGATION.SIGNOUT</a
        >
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar
    #appSidebar
    [fixed]="true"
    [display]="'lg'"
    [minimized]="sidebarMinimized"
    (minimizedChange)="toggleMinimize($event)"
  >
    <app-sidebar-nav
      [navItems]="navItems"
      [perfectScrollbar]
      [disabled]="appSidebar.minimized"
    ></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <div class="row">
      <div class="col-11 pr-0">
        <cui-breadcrumb> </cui-breadcrumb>
      </div>
      <div class="col-1 help d-flex justify-content-end align-items-center">
        <i
          class="fa fa-question-circle pointer"
          [class.text-primary]="!hasPageHelp"
          (click)="onClickPageHelp()"
        ></i>
      </div>
    </div>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
<app-default-footer></app-default-footer>
