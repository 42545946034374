import { Action } from '@ngrx/store';
import {
  BridgeInterface,
  BridgeInterfaceConfiguration,
} from '@secure/devices/store/models/bridge-interface-configuration.model';
import { Columns } from '../models/shared.model';

export enum ActionTypes {
  SET_BRIDGE_INTERFACE_CONFIGURAION = 'SET_BRIDGE_INTERFACE_CONFIGURAION',
  CLEAR_CURRENT_BRIDGE_INTERFACE_CONFIGURATION = 'CLEAR_CURRENT_BRIDGE_INTERFACE_CONFIGURATION',
  BRIDGE_INTERFACE_COLUMN_SELECT = 'BRIDGE_INTERFACE_COLUMN_SELECT',
  BRIDGE_INTERFACE_COLUMNS_SELECT_ALL = 'BRIDGE_INTERFACE_COLUMNS_SELECT_ALL',
  BRIDGE_INTERFACE_COLUMN_DESELECT = 'BRIDGE_INTERFACE_COLUMN_DESELECT',
  BRIDGE_INTERFACE_SHUFFLED_COLUMNS = 'BRIDGE_INTERFACE_SHUFFLED_COLUMNS',
  BRIDGE_INTERFACE_ADD = 'BRIDGE_INTERFACE_ADD',
  BRIDGE_INTERFACE_EDIT = 'BRIDGE_INTERFACE_EDIT',
  ADD_BRIDGE_MEMBER = 'ADD_BRIDGE_MEMBER',
  UPDATE_BRIDGES = 'UPDATE_BRIDGES',
  DELETE_BRIDGE = 'DELETE_BRIDGE',
}

export class SetBridgeInterfaceConfiguration implements Action {
  readonly type = ActionTypes.SET_BRIDGE_INTERFACE_CONFIGURAION;
  constructor(public payload: BridgeInterfaceConfiguration) {}
}

export class ClearCurrentBridgeInterfaceConfiguration implements Action {
  readonly type = ActionTypes.CLEAR_CURRENT_BRIDGE_INTERFACE_CONFIGURATION;
}

export class BridgeInterfaceColumnSelect implements Action {
  readonly type = ActionTypes.BRIDGE_INTERFACE_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class BridgeInterfaceColumnsSelectAll implements Action {
  readonly type = ActionTypes.BRIDGE_INTERFACE_COLUMNS_SELECT_ALL;
}

export class BridgeInterfaceColumnDeSelect implements Action {
  readonly type = ActionTypes.BRIDGE_INTERFACE_COLUMN_DESELECT;
  constructor(public payload: string) {}
}
export class BridgeInterfaceShuffledColumns implements Action {
  readonly type = ActionTypes.BRIDGE_INTERFACE_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export class AddBridgeInterface implements Action {
  readonly type = ActionTypes.BRIDGE_INTERFACE_ADD;
  constructor(public payload: BridgeInterface) {}
}

export class EditBridgeInterface implements Action {
  readonly type = ActionTypes.BRIDGE_INTERFACE_EDIT;
  constructor(public payload: BridgeInterface) {}
}

export class AddBridgeMember implements Action {
  readonly type = ActionTypes.ADD_BRIDGE_MEMBER;
  constructor(public payload) {}
}

export class UpdateBridgeMembers implements Action {
  readonly type = ActionTypes.UPDATE_BRIDGES;
  constructor(public payload) {}
}

export class DeleteBridgeMember implements Action {
  readonly type = ActionTypes.DELETE_BRIDGE;
  constructor(public payload: string) {}
}

export type Actions =
  | SetBridgeInterfaceConfiguration
  | ClearCurrentBridgeInterfaceConfiguration
  | BridgeInterfaceColumnSelect
  | BridgeInterfaceColumnsSelectAll
  | BridgeInterfaceColumnDeSelect
  | BridgeInterfaceShuffledColumns
  | AddBridgeInterface
  | EditBridgeInterface
  | AddBridgeMember
  | UpdateBridgeMembers
  | DeleteBridgeMember;
