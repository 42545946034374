import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IntervalService {
  interval;

  setInterval(time: number, callback: () => void) {
    clearInterval(this.interval);
    this.interval = setInterval(callback, time);
  }

  getInterval() {
    return this.interval;
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = undefined;
  }
}
