<div class="modal-info" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()" translate>
        SHARED.CLOSE
      </button>
      <button *ngIf="hasActionButton" type="button" class="btn btn-info" (click)="confirmAction()">
        {{ buttonText }}
      </button>
    </div>
  </div>
</div>
