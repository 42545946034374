import { Action, createReducer, on } from '@ngrx/store';
import { ApplicationDomainState } from '../../application-domains.model';
import * as defaultDomainsActions from './default-domains.action';

const initialState: ApplicationDomainState = {
  globalSearch: '',
  application_domains: [],
  shuffledColumns: [
    { key: 'Name', field: 'name', hide: false, sort: '', minWidth: 75, width: 155 },
    { key: 'Domains', field: 'domains', hide: false, sort: '', minWidth: 75, width: 85 },
  ],
  pagination: {
    current_page: 1,
    end_at: 0,
    is_first_page: false,
    is_last_page: false,
    next_page: 0,
    prev_page: 0,
    per_page: 10,
    start_at: 0,
    total_count: 0,
    total_pages: 0,
  },
};

const reducer = createReducer(
  initialState,
  on(defaultDomainsActions.setDefaultDomainsList, (state, props) => ({
    ...state,
    application_domains: props.applicationDomainsResponse.application_domains,
    pagination: props.applicationDomainsResponse.pagination,
  })),
  on(defaultDomainsActions.setDefaultDomainsGlobalSearch, (state, props) => ({
    ...state,
    globalSearch: props.globalSearch,
  })),
  on(defaultDomainsActions.setDefaultDomainsCurrentPage, (state, props) => ({
    ...state,
    pagination: {
      ...state.pagination,
      current_page: props.currentPage,
    },
  })),
  on(defaultDomainsActions.setDefaultDomainsShuffledColumns, (state, props) => ({
    ...state,
    shuffledColumns: props.shuffledColumns,
  })),
  on(defaultDomainsActions.setDefaultDomainsColumnSelect, (state, props) => {
    const selectedColumn = state.shuffledColumns.find(
      (column) => column.field === props.columnField
    );
    if (selectedColumn) {
      selectedColumn.hide = false;
    }
    return {
      ...state,
    };
  }),
  on(defaultDomainsActions.setDefaultDomainsColumnSelectall, (state) => ({
    ...state,
    shuffledColumns: state.shuffledColumns.map((column) => {
      return {
        ...column,
        hide: false,
      };
    }),
  })),
  on(defaultDomainsActions.setDefaultDomainsColumnDeselect, (state, props) => {
    const unSelectedColumn = state.shuffledColumns.find(
      (column) => column.field === props.columnField
    );
    if (unSelectedColumn) {
      unSelectedColumn.hide = true;
    }
    return {
      ...state,
    };
  }),
  on(defaultDomainsActions.setDefaultDomainsColumnsSort, (state, props) => {
    state.shuffledColumns.forEach((column) => {
      let fieldMatch = false;
      props.sortedColumns.forEach((data) => {
        if (column.field === data.colId) {
          fieldMatch = true;
          column.sort = data.sort;
        }
      });

      if (!fieldMatch) {
        column.sort = '';
      }
    });
    return { ...state };
  })
);

export function defaultDomainsReducer(state: ApplicationDomainState | undefined, action: Action) {
  return reducer(state, action);
}
