import { filter, includes, map, pull } from 'lodash';
import { Actions, ActionTypes } from '../actions/bridge-interface-configuration.action';
import {
  BridgeInterface,
  BridgeInterfaceConfigurationReducer,
} from '../models/bridge-interface-configuration.model';

export const initialState: BridgeInterfaceConfigurationReducer = {
  id: 0,
  deviceId: 0,
  interfaces: [],
  shuffledColumns: [
    { key: 'Name', field: 'user_defined_name', hide: false },
    { key: 'MAC Address', field: 'mac_address', hide: false },
    { key: 'Admin Status', field: 'enable_interface', hide: false },
    { key: 'Dynamic IP', field: 'dynamic_ip_addressing_mode', hide: false },
    { key: 'DHCP Server', field: 'dhcp_server_configurations', hide: false },
    { key: 'Runtime Status', field: 'runtime_status', hide: false },
    { key: 'Runtime IP Addresses', field: 'system_defined_ip_addresses', hide: false },
    { key: 'Default Gateway', field: 'default_gateway', hide: false },
    { key: 'Type', field: 'network_side', hide: false },
    { key: 'Members', field: 'member_ids', hide: false },
    { key: 'TX-Packets', field: 'statistics.sent.packets', hide: false },
    { key: 'TX-Bytes', field: 'statistics.sent.bytes', hide: false },
    { key: 'TX-Errors', field: 'statistics.sent.errors', hide: false },
    { key: 'RX-Packets', field: 'statistics.received.packets', hide: false },
    { key: 'RX-Bytes', field: 'statistics.received.bytes', hide: false },
    { key: 'RX-Errors', field: 'statistics.received.errors', hide: false },
  ],
};

export function bridgeInterfaceConfigurationReducer(
  state = initialState,
  action: Actions
): BridgeInterfaceConfigurationReducer {
  switch (action.type) {
    case ActionTypes.SET_BRIDGE_INTERFACE_CONFIGURAION:
      return {
        ...state,
        id: action.payload.id,
        deviceId: action.payload.device_id,
        interfaces: action.payload.interfaces,
      };
    case ActionTypes.CLEAR_CURRENT_BRIDGE_INTERFACE_CONFIGURATION:
      return initialState;
    case ActionTypes.BRIDGE_INTERFACE_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case ActionTypes.BRIDGE_INTERFACE_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case ActionTypes.BRIDGE_INTERFACE_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case ActionTypes.BRIDGE_INTERFACE_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    case ActionTypes.UPDATE_BRIDGES:
      const oldBridgeInterfacesWithHardwareRemoved: BridgeInterface[] = map(
        state.interfaces,
        (bridgeInterface: BridgeInterface): BridgeInterface => {
          return {
            ...bridgeInterface,
            member_ids: pull(bridgeInterface.member_ids, action.payload.interfaceId),
          };
        }
      );

      const bridgeUpdatedInterfaces: BridgeInterface[] = map(
        oldBridgeInterfacesWithHardwareRemoved,
        (bridgeInterface: BridgeInterface): BridgeInterface =>
          bridgeInterface.id === action.payload.bridgeId
            ? {
                ...bridgeInterface,
                member_ids: [...bridgeInterface.member_ids, action.payload.interfaceId],
              }
            : bridgeInterface
      );

      return {
        ...state,
        interfaces: bridgeUpdatedInterfaces,
      };
    case ActionTypes.BRIDGE_INTERFACE_ADD:
      return {
        ...state,
        interfaces: [...state.interfaces, action.payload],
      };
    case ActionTypes.ADD_BRIDGE_MEMBER:
      const addBridgeMemberList = map(state.interfaces, (bridgeInterface: BridgeInterface) =>
        bridgeInterface.id === action.payload.bridgeId
          ? {
              ...bridgeInterface,
              member_ids: [...bridgeInterface.member_ids, action.payload.interfaceId],
            }
          : bridgeInterface
      );
      return {
        ...state,
        interfaces: addBridgeMemberList,
      };
    case ActionTypes.BRIDGE_INTERFACE_EDIT:
      const updatedBridgeList = map(state.interfaces, (bridgeInterface: BridgeInterface) =>
        bridgeInterface.id === action.payload.id
          ? { ...bridgeInterface, ...action.payload }
          : bridgeInterface
      );
      return {
        ...state,
        interfaces: updatedBridgeList,
      };
    case ActionTypes.DELETE_BRIDGE:
      const id = action.payload;
      const modifiedBridgeList = filter(
        state.interfaces,
        (bridgeInterface: BridgeInterface) => !includes(id, bridgeInterface.id)
      );
      return {
        ...state,
        interfaces: modifiedBridgeList,
      };
    default:
      return state;
  }
}
