import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridCellRendererButtonsComponent } from '@shared/components/ag-grid-cell-renderer-buttons/ag-grid-cell-renderer-buttons.component';
import { ColumnWidth, Pagination, SortColumns } from '@shared/models/shared.model';
import { GlobalLanguageService } from '@shared/services/global-language.service';
import {
  ColDef,
  Column,
  ColumnApi,
  ColumnResizedEvent,
  ColumnState,
  GridApi,
  GridReadyEvent,
  HeaderValueGetterParams,
  RowClickedEvent,
  RowNode,
  SortChangedEvent,
} from 'ag-grid-community';
import { Observable } from 'rxjs';
import { CustomButton } from '../ag-grid-cell-renderer-buttons/ag-grid-cell-renderer-buttons.model';
import { AgGridFloatingFilterComponent } from '../ag-grid-floating-filter/ag-grid-floating-filter.component';
import { CustomButtonConfig } from './list-grid.model';

@Component({
  selector: 'app-list-grid',
  templateUrl: './list-grid.component.html',
})
export class ListGridComponent implements OnInit, OnDestroy {
  @Input() rowData: Observable<any[]>;
  @Input() columnDefs: ColDef[];
  @Input() noRowsText: string;
  @Input() addNewUrl: string;
  @Input() editUrl: string;
  @Input() hasDelete = true;
  @Input() disableDelete: boolean;
  @Input() disableEdit: boolean;
  @Input() hasFloatingFilter: boolean;
  @Input() isFloatingFilterEnabled: boolean;
  @Input() customButtonConfig: CustomButtonConfig[];
  @Input() rowHeight = 25;
  @Input() suppressRowClickSelection: boolean;
  @Input() hasPagination: boolean;
  @Input() paginationPageSize: number;
  @Input() pagination: Pagination;
  @Input() context: CustomButton[];
  @Input() overlayNoRowsTemplate: string;
  @Input() defaultColDef: ColDef;
  @Input() hasGlobalSearch: boolean;

  @Output() private delete = new EventEmitter<string[]>();
  @Output() private selectionChanged = new EventEmitter<any[]>();
  @Output() private gridReady = new EventEmitter<GridReadyEvent>();
  @Output() private pageChange = new EventEmitter<number>();
  @Output() private columnResized = new EventEmitter<ColumnWidth[]>();
  @Output() private sortChanged = new EventEmitter<SortColumns[]>();
  @Output() private toggleFilter = new EventEmitter<boolean>();
  @Output() private rowClicked = new EventEmitter();
  @Output() private globalSearchSubmit = new EventEmitter<string>();

  customColDef: ColDef;
  isAutoSize: boolean;
  globalSearch: string;
  private api: GridApi | undefined;
  private columnApi: ColumnApi;

  frameworkComponents: {
    actionRenderer: typeof AgGridCellRendererButtonsComponent;
    floatingFilterComponent: typeof AgGridFloatingFilterComponent;
  };

  constructor(
    private globalTranslate: GlobalLanguageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.customColDef = {
      floatingFilterComponent: 'floatingFilterComponent',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      headerValueGetter: (param: HeaderValueGetterParams) => {
        if (param.colDef.headerName) {
          return this.globalTranslate.getTranslation(param.colDef.headerName);
        }
      },
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
    };
    this.frameworkComponents = {
      actionRenderer: AgGridCellRendererButtonsComponent,
      floatingFilterComponent: AgGridFloatingFilterComponent,
    };
  }

  ngOnInit(): void {
    this.overlayNoRowsTemplate = this.globalTranslate.getTranslation(this.overlayNoRowsTemplate);
  }

  ngOnDestroy(): void {
    this.destroyGrid();
  }

  onGridReady(param: GridReadyEvent): void {
    this.api = param.api;
    this.columnApi = param.columnApi;
    this.gridReady.emit(param);
  }

  get columnDef() {
    return { ...this.defaultColDef, ...this.customColDef };
  }

  private destroyGrid() {
    if (this.api) {
      this.api.destroy();
      this.api = null;
    }
  }

  onColumnResized(event: ColumnResizedEvent): void {
    const updatedColumns: ColumnWidth[] = [];
    event.columns.forEach((column: Column) => {
      updatedColumns.push({ field: column.getId(), width: column.getActualWidth() });
    });
    this.columnResized.emit(updatedColumns);
  }

  onPageChange(page: number): void {
    this.pageChange.emit(page);
  }

  onSelectionChanged(): void {
    this.selectionChanged.emit(this.api.getSelectedRows());
  }

  onRowClicked(params: RowClickedEvent): void {
    this.rowClicked.emit(params);
  }

  onEdit(): void {
    if (this.noOfSelectedRows === 1) {
      const selectedId = this.api.getSelectedNodes()[0].data.id;
      this.router.navigate([this.editUrl, selectedId], { relativeTo: this.route });
    }
  }

  onDelete(): void {
    if (!this.isDeleteDisabled) {
      const selectedIds: string[] = this.api
        .getSelectedNodes()
        .map((rowData: RowNode) => rowData.data.id);
      this.delete.emit(selectedIds);
    }
  }

  get noOfSelectedRows(): number {
    return this.api?.getSelectedNodes()?.length;
  }

  get isDeleteDisabled(): boolean {
    return !this.noOfSelectedRows || this.disableDelete;
  }

  onChangeColumnSize(): void {
    if (this.isAutoSize) {
      this.sizeColumnsToFit();
    } else {
      this.autoSizeAllColumns();
    }
  }

  public onSortChanged(event: SortChangedEvent): void {
    const sortedColumn = event.columnApi
      .getColumnState()
      .filter((col: ColumnState) => Boolean(col.sort))
      .map((col: ColumnState) => ({ colId: col.colId, sort: col.sort }));
    this.sortChanged.emit(sortedColumn);
  }

  public onToggleFloatingFilter(): void {
    this.isFloatingFilterEnabled = !this.isFloatingFilterEnabled;
    this.toggleFilter.emit(this.isFloatingFilterEnabled);
  }

  private sizeColumnsToFit(): void {
    this.api?.sizeColumnsToFit();
    this.isAutoSize = false;
  }

  private autoSizeAllColumns(): void {
    this.columnApi?.autoSizeAllColumns();
    this.isAutoSize = true;
  }

  public isLoading(isLoading: (() => boolean) | undefined): boolean {
    return isLoading === undefined ? false : isLoading();
  }

  public onGlobalSearchChange(value: string): void {
    this.globalSearch = value;
  }

  public onGlobalSearchSubmit(): void {
    this.globalSearchSubmit.emit(this.globalSearch);
  }
}
