import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];
  private goingBack = false;

  constructor(
    private router: Router,
    private location: Location
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
        this.back();
      } else if (event instanceof NavigationCancel) {
        this.goingBack = false;
      } else if (event instanceof NavigationEnd) {
        this.historyPop(event);
      }
    });
  }

  historyPop(event): void {
    if (this.goingBack) {
      this.history.pop();
      this.history.pop();
    }
    this.goingBack = false;
    this.history.push(event.urlAfterRedirects);
  }

  back(): void {
    const urlToBack = this.history[this.history.length - 2];
    this.goingBack = true;
    if (urlToBack) {
      this.router.navigateByUrl(urlToBack);
    } else {
      this.router.navigateByUrl('/');
    }
  }

  getHistory() {
    return this.history;
  }

  replaceState(url: string): void {
    this.history[this.history.length - 1] = url;
    this.location.replaceState(url);
  }
}
