import { createAction, props } from '@ngrx/store';
import { ApplicationDomainsResponse } from '../../application-domains.model';
import { Columns, SortColumns } from '@shared/models/shared.model';

export const setCustomDomainsList = createAction(
  '[Custom Domain] custom domains list',
  props<{ applicationDomainsResponse: ApplicationDomainsResponse }>()
);

export const setCustomDomainsGlobalSearch = createAction(
  '[Custom Domain] global search',
  props<{ globalSearch: string }>()
);

export const setCustomDomainsCurrentPage = createAction(
  '[Custom Domain] current page',
  props<{ currentPage: number }>()
);

export const setCustomDomainsShuffledColumns = createAction(
  '[Custom Domain] shuffled column',
  props<{ shuffledColumns: Columns[] }>()
);

export const setCustomDomainsColumnSelect = createAction(
  '[Custom Domain] column select',
  props<{ columnField: string }>()
);

export const setCustomDomainsColumnSelectall = createAction('[Custom Domain] column selectall');

export const setCustomDomainsColumnDeselect = createAction(
  '[Custom Domain] column deselect',
  props<{ columnField: string }>()
);

export const setCustomDomainsColumnsSort = createAction(
  '[Default Domains] column sort',
  props<{ sortedColumns: SortColumns[] }>()
);
