import { Action } from '@ngrx/store';
import { UsbInterfaceConfiguration } from '@secure/devices/store/models/usb-interface-configuration.model';
import { Columns } from '../models/shared.model';

export enum ActionTypes {
  SET_USB_INTERFACE_CONFIGURAION = 'SET_USB_INTERFACE_CONFIGURAION',
  CLEAR_CURRENT_USB_INTERFACE_CONFIGURATION = 'CLEAR_CURRENT_USB_INTERFACE_CONFIGURATION',
  USB_INTERFACE_COLUMN_SELECT = 'USB_INTERFACE_COLUMN_SELECT',
  USB_INTERFACE_COLUMNS_SELECT_ALL = 'USB_INTERFACE_COLUMNS_SELECT_ALL',
  USB_INTERFACE_COLUMN_DESELECT = 'USB_INTERFACE_COLUMN_DESELECT',
  USB_INTERFACE_SHUFFLED_COLUMNS = 'USB_INTERFACE_SHUFFLED_COLUMNS',
}

export class SetUsbInterfaceConfiguration implements Action {
  readonly type = ActionTypes.SET_USB_INTERFACE_CONFIGURAION;
  constructor(public payload: UsbInterfaceConfiguration) {}
}

export class ClearCurrentUsbInterfaceConfiguration implements Action {
  readonly type = ActionTypes.CLEAR_CURRENT_USB_INTERFACE_CONFIGURATION;
}

export class UsbInterfaceColumnSelect implements Action {
  readonly type = ActionTypes.USB_INTERFACE_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class UsbInterfaceColumnsSelectAll implements Action {
  readonly type = ActionTypes.USB_INTERFACE_COLUMNS_SELECT_ALL;
}

export class UsbInterfaceColumnDeSelect implements Action {
  readonly type = ActionTypes.USB_INTERFACE_COLUMN_DESELECT;
  constructor(public payload: string) {}
}
export class UsbInterfaceShuffledColumns implements Action {
  readonly type = ActionTypes.USB_INTERFACE_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export type Actions =
  | SetUsbInterfaceConfiguration
  | ClearCurrentUsbInterfaceConfiguration
  | UsbInterfaceColumnSelect
  | UsbInterfaceColumnsSelectAll
  | UsbInterfaceColumnDeSelect
  | UsbInterfaceShuffledColumns;
